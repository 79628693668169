<template>
  <div class="guide-line">
    <h5 class="text-left mb-10 mt-20"> EXTERIOR </h5>
        <b-row class="px-3">
          <b-col v-for="(example, idx) in Exterior" :key="idx" cols="6" md="4" class="p-2">
            <img :src="require(`@/assets/images/guideline/${example.imgName}`)" width="100%" class="rounded img"/>
            <p class="text-center bold fs-12 mt-10 mb-0" v-if="example.text"> {{ example.text }}</p>
          </b-col>
        </b-row>
      <h5 class="text-left mb-10 mt-20"> INTERIOR </h5>
        <b-row class="px-3">
          <b-col cols="12">
            <b-row>
              <b-col v-for="(example, idx) in Interior" :key="idx" cols="6" md="4" class="p-2">
                <img :src="require(`@/assets/images/guideline/${example.imgName}`)" width="100%" class="rounded img"/>
                <p class="text-center bold fs-12 mt-10 mb-0" v-if="example.text"> {{ example.text }}</p>           
              </b-col>
            </b-row>
          </b-col>
        </b-row>
  </div>
</template>

<script>
import { GUIDE_LINE_EXTERIOR, GUIDE_LINE_INTERIOR } from '@/config/constants'
export default {
  name: 'Guideline',
  props: ['show'],
  data() {
    return {
      Exterior: GUIDE_LINE_EXTERIOR,
      Interior: GUIDE_LINE_INTERIOR,
    }
  },
  methods: {
    handleSwipe() {
      if (this.cur === 1) {
        this.cur = this.cur + 1;
        this.title = 'Bad example'
      } else {
        this.cur = this.cur - 1;
        this.title = 'Good example'
      }
    }
  },
  computed: {
    showModal() {
      return this.show;
    }
  },
}
</script>

<style lang="scss">
.good-example {
  padding: 0.125rem 0.25rem !important;
}
.img {
  height: 80%;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
  @media screen and(min-width: 768px) {
    height: 200px;
  }
}
.guide-line {
  position: relative;
}
.modal-header {
  border: none;
}
.modal-header .close {
  z-index: 100;
}
.bold {
  font-family: Avenir Next LT W05 Regular;
}
</style>